export type PackagePerk = {
  key: string;
  label: string;
  class: 'standard' | 'premium';
};

export const sauna: PackagePerk = {
  key: 'sauna',
  label: 'Sauna',
  class: 'standard',
};

export const jacuzzi: PackagePerk = {
  key: 'jacuzzi',
  label: 'Jacuzzi',
  class: 'standard',
};

export const adventureShower: PackagePerk = {
  key: 'adventure-shower',
  label: 'Erlebnisdusche',
  class: 'standard',
};

export const foodAndBeverages: PackagePerk = {
  key: 'food-and-beverages',
  label: 'Bestelle Speisen & Getränke',
  class: 'standard',
};

export const beauty: PackagePerk = {
  key: 'beauty',
  label: 'Bestelle Spa-Produkte',
  class: 'standard',
};

export const chillOutLounge: PackagePerk = {
  key: 'chill-out-lounge',
  label: 'Eigene "Chill Out" Lounge',
  class: 'standard',
};

export const washroom: PackagePerk = {
  key: 'washroom',
  label: 'WC',
  class: 'standard',
};

export const space: PackagePerk = {
  key: 'space',
  label: '35qm nur für dich',
  class: 'standard',
};

export const voiceControl: PackagePerk = {
  key: 'voice-control',
  label: 'Sprachsteuerung',
  class: 'standard',
};

export const moodControl: PackagePerk = {
  key: 'mood-control',
  label: 'Atmosphärensteuerung',
  class: 'standard',
};

export const personalService: PackagePerk = {
  key: 'personal-service',
  label: 'Kontaktloser Service',
  class: 'standard',
};

export const entertainment: PackagePerk = {
  key: 'entertainment',
  label: 'Entertainment',
  class: 'standard',
};

export const tanningBed: PackagePerk = {
  key: 'tanning-bed',
  label: 'Sonnenbank',
  class: 'premium',
};

export const massageChair: PackagePerk = {
  key: 'massage-chair',
  label: 'Massagesessel',
  class: 'premium',
};

export const xbox: PackagePerk = {
  key: 'xbox',
  label: 'XBOX + Unlimited Gaming',
  class: 'premium',
};

export const bathrobe: PackagePerk = {
  key: 'bathrobe',
  label: 'Bademantel',
  class: 'premium',
};

export const slipper: PackagePerk = {
  key: 'slipper',
  label: 'Slipper',
  class: 'premium',
};
