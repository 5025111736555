<ng-container
  *ngIf="{
    type: _type$ | async,
    isDisabled: _disabled$ | async,
    usePrimaryLook: (_look$ | async) === 'primary',
    useSecondaryLook: (_look$ | async) === 'secondary',
    useShimmerLook: (_look$ | async) === 'shimmer',
    useFlareLook: (_look$ | async) === 'flare',
    useBorderLook: (_look$ | async) === 'border'
  } as state"
>
  <button
    [type]="state.type"
    [disabled]="state.isDisabled"
    [class.primary]="state.usePrimaryLook"
    [class.secondary]="state.useSecondaryLook"
    [class.shimmer]="state.useShimmerLook"
    [class.flare]="state.useFlareLook"
    [class.border]="state.useBorderLook"
  >
    <ng-content></ng-content>
  </button>
</ng-container>
