import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Icon } from './icon';

@Component({
  selector: 'wlx-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() name: Icon | undefined;

  @Input() x1: string | undefined;
  @Input() x2: string | undefined;
  @Input() x3: string | undefined;
  @Input() x4: string | undefined;
  @Input() x5: string | undefined;
  @Input() x6: string | undefined;

  @HostBinding('class.x1') _x1 = false;
  @HostBinding('class.x2') _x2 = false;
  @HostBinding('class.x3') _x3 = false;
  @HostBinding('class.x4') _x4 = false;
  @HostBinding('class.x5') _x5 = false;
  @HostBinding('class.x6') _x6 = false;

  public ngOnInit(): void {
    this.evaluateSizes();
  }

  public evaluateSizes(): void {
    if (this.multipleSizesSpecified()) {
      throw new Error('Received more than one size input, but only one should be specified');
    }

    this.invalidateSizes();

    if (this.shouldScaleToX1()) this._x1 = true;
    if (this.shouldScaleToX2()) this._x2 = true;
    if (this.shouldScaleToX3()) this._x3 = true;
    if (this.shouldScaleToX4()) this._x4 = true;
    if (this.shouldScaleToX5()) this._x5 = true;
    if (this.shouldScaleToX6()) this._x6 = true;
  }

  public invalidateSizes(): void {
    this._x1 = this._x2 = this._x3 = this._x4 = this._x5 = this._x6 = false;
  }

  public multipleSizesSpecified(): boolean {
    return this.countSizes() > 1;
  }

  public countSizes(): number {
    return [
      this.shouldScaleToX1(),
      this.shouldScaleToX2(),
      this.shouldScaleToX3(),
      this.shouldScaleToX4(),
      this.shouldScaleToX5(),
      this.shouldScaleToX6(),
    ]
      .map<number>(b => (b ? 1 : 0))
      .reduce((a, b) => a + b);
  }

  public shouldScaleToX1(): boolean {
    return this.x1 !== undefined;
  }
  public shouldScaleToX2(): boolean {
    return this.x2 !== undefined;
  }
  public shouldScaleToX3(): boolean {
    return this.x3 !== undefined;
  }
  public shouldScaleToX4(): boolean {
    return this.x4 !== undefined;
  }
  public shouldScaleToX5(): boolean {
    return this.x5 !== undefined;
  }
  public shouldScaleToX6(): boolean {
    return this.x6 !== undefined;
  }
}
