<wlx-heading>
  <wlx-heading-lead x2 *ngIf="title">
    {{ title }}
  </wlx-heading-lead>
  <wlx-heading-subdivision x3 *ngIf="message">
    <br />
    <span class="dialog-card-message">
      {{ message }}
    </span>
    @if (enableCloseButton) {
      <wlx-button (click)="dialogService.close()">{{ closeButtonLabel || 'Schließen' }}</wlx-button>
    }
  </wlx-heading-subdivision>
</wlx-heading>
