import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingComponent } from './heading.component';
import { HeadingLeadComponent } from './lead/lead.component';
import { HeadingSubdivisionComponent } from './subdivision/subdivision.component';

@NgModule({
  declarations: [HeadingComponent, HeadingLeadComponent, HeadingSubdivisionComponent],
  imports: [CommonModule],
  exports: [HeadingComponent, HeadingLeadComponent, HeadingSubdivisionComponent],
})
export class HeadingModule {}
