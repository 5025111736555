import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { classic, Package } from '../package';
import { PackageComponent } from '../package.component';

@Component({
  selector: 'wlx-package-option',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageOptionComponent implements OnInit {
  @Input()
  set value(value: Package) {
    this._value = value;
  }
  get value(): Package {
    return this._value;
  }

  @Input()
  set disabled(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._disabled$.next(value);
        return;
      case 'string':
        this._disabled$.next(true);
        return;
      default:
        this._disabled$.next(false);
        return;
    }
  }
  get disabled(): boolean {
    return this._disabled$.value;
  }

  _value = classic;
  _disabled$ = new BehaviorSubject<boolean>(false);

  constructor(private _packageComponent: PackageComponent) {}

  ngOnInit(): void {
    if (this.value === undefined) {
      throw new Error('Input "package" for "PackageOptionComponent" expected value, received none');
    }
    this._packageComponent._packageOptions.push({
      disabled: this.disabled || false,
      package: this._value,
    });
  }
}
