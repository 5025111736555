import {
  adventureShower,
  bathrobe,
  beauty,
  chillOutLounge,
  entertainment,
  foodAndBeverages,
  jacuzzi,
  massageChair,
  moodControl,
  PackagePerk,
  personalService,
  sauna,
  slipper,
  space,
  tanningBed,
  voiceControl,
  washroom,
  xbox,
} from './perk/perk';

export type Package = {
  id: string;
  display: PackageDisplay;
  service: PackageService;
  pricing: PackagePricing;
};

export type PackageDisplay = {
  name: string;
  image: string;
};

export type PackageService = PackageStandardService | PackagePremiumService;
export type PackageStandardService = {
  class: 'standard';
  standardPerks: Array<PackagePerk>;
  premiumPerks: Array<PackagePerk>;
};
export type PackagePremiumService = {
  class: 'premium';
  standardPerks: Array<PackagePerk>;
  premiumPerks: Array<PackagePerk>;
};

export type PackagePricing = {
  unitsPerPersonPerHour: number;
};

export const classic: Package = {
  id: 'CLASSIC',
  display: {
    name: 'wellbase',
    image: '/assets/renderings/jacuzzi-256x256.png',
  },
  service: {
    class: 'standard',
    standardPerks: [
      sauna,
      jacuzzi,
      adventureShower,
      foodAndBeverages,
      beauty,
      chillOutLounge,
      washroom,
      space,
      voiceControl,
      moodControl,
      personalService,
      entertainment,
    ],
    premiumPerks: [],
  },
  pricing: {
    unitsPerPersonPerHour: 2990,
  },
};

export const workout: Package = {
  id: 'WORKOUT',
  display: {
    name: 'Workout',
    image: '/assets/renderings/jacuzzi-256x256.png',
  },
  service: {
    class: 'premium',
    standardPerks: [...classic.service.standardPerks],
    premiumPerks: [tanningBed, massageChair],
  },
  pricing: {
    unitsPerPersonPerHour: 1000,
  },
};

export const game: Package = {
  id: 'GAME',
  display: {
    name: 'Gaming',
    image: '/assets/renderings/jacuzzi-256x256.png',
  },
  service: {
    class: 'premium',
    standardPerks: [...classic.service.standardPerks],
    premiumPerks: [xbox, bathrobe, slipper],
  },
  pricing: {
    unitsPerPersonPerHour: 1000,
  },
};

export const massage: Package = {
  id: 'MASSAGE',
  display: {
    name: 'Massage',
    image: '/assets/renderings/jacuzzi-256x256.png',
  },
  service: {
    class: 'premium',
    standardPerks: [...classic.service.standardPerks],
    premiumPerks: [],
  },
  pricing: {
    unitsPerPersonPerHour: 1000,
  },
};

export const karaoke: Package = {
  id: 'KARAOKE',
  display: {
    name: 'Karaoke',
    image: '/assets/renderings/jacuzzi-256x256.png',
  },
  service: {
    class: 'premium',
    standardPerks: [...classic.service.standardPerks],
    premiumPerks: [],
  },
  pricing: {
    unitsPerPersonPerHour: 1000,
  },
};

export const packages = [classic, workout, game, massage, karaoke];
