import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wlx-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  set type(value: ButtonComponentType | undefined) {
    if (value === undefined) {
      return;
    }
    this._type$.next(value);
  }

  @Input()
  set look(value: ButtonComponentLook | undefined) {
    if (value === undefined) {
      return;
    }
    this._look$.next(value);
  }

  @Input()
  set disabled(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._disabled$.next(value);
        return;
      case 'string':
        this._disabled$.next(true);
        return;
      default:
        this._disabled$.next(false);
        return;
    }
  }

  @Input()
  set button(_: string | undefined) {
    this._type$.next('button');
  }

  @Input()
  set submit(_: string | undefined) {
    this._type$.next('submit');
  }

  @Input()
  set reset(_: string | undefined) {
    this._type$.next('reset');
  }

  @Input()
  set primary(_: string | undefined) {
    this._look$.next('primary');
  }

  @Input()
  set secondary(_: string | undefined) {
    this._look$.next('secondary');
  }

  public _type$ = new BehaviorSubject<ButtonComponentType>('button');
  public _look$ = new BehaviorSubject<ButtonComponentLook>('primary');
  public _disabled$ = new BehaviorSubject<boolean>(false);
}

export type ButtonComponentType = 'button' | 'submit' | 'reset';
export type ButtonComponentLook = 'primary' | 'secondary' | 'shimmer' | 'flare' | 'border';
