import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageComponent } from './package.component';
import { PackagePerkComponent } from './perk/perk.component';
import { PerkGridRendererComponent } from './perk-grid-renderer/perk-grid-renderer.component';
import { HeadingModule } from '../heading/heading.module';
import { PackageOptionComponent } from './option/option.component';
import { FormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [
    PackageComponent,
    PackagePerkComponent,
    PerkGridRendererComponent,
    PackageOptionComponent,
  ],
  imports: [CommonModule, FormsModule, IconModule, HeadingModule],
  exports: [
    PackageComponent,
    PackagePerkComponent,
    PerkGridRendererComponent,
    PackageOptionComponent,
  ],
})
export class PackageModule {}
