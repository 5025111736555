import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive()
export abstract class HeadingBaseComponent implements OnInit {
  @Input() public centerAligned: undefined | string;
  @Input() public endAligned: undefined | string;
  @Input() public startAligned: undefined | string;
  @Input() public x1: undefined | string;
  @Input() public x2: undefined | string;
  @Input() public x3: undefined | string;
  @Input() public x4: undefined | string;
  @Input() public x5: undefined | string;
  @Input() public x6: undefined | string;

  @HostBinding('class.alignCenter') protected _alignCenter = false;
  @HostBinding('class.alignEnd') protected _alignEnd = false;
  @HostBinding('class.alignStart') protected _alignStart = false;
  @HostBinding('class.x1') protected _x1 = false;
  @HostBinding('class.x2') protected _x2 = false;
  @HostBinding('class.x3') protected _x3 = false;
  @HostBinding('class.x4') protected _x4 = false;
  @HostBinding('class.x5') protected _x5 = false;
  @HostBinding('class.x6') protected _x6 = false;

  public ngOnInit(): void {
    this.evaluateAligmentInputs();
    this.evaluateSizeInputs();
  }

  public countAlignmentInputs(): number {
    return [this.shouldAlignCenter(), this.shouldAlignEnd(), this.shouldAlignStart()]
      .map<number>(b => (b ? 1 : 0))
      .reduce((a, b) => a + b);
  }

  public countSizeInputs(): number {
    return [
      this.shouldScaleToX1(),
      this.shouldScaleToX2(),
      this.shouldScaleToX3(),
      this.shouldScaleToX4(),
      this.shouldScaleToX5(),
      this.shouldScaleToX6(),
    ]
      .map<number>(b => (b ? 1 : 0))
      .reduce((a, b) => a + b);
  }

  public evaluateAligmentInputs(): void {
    if (this.multipleAlignmentsSpecified()) {
      throw new Error('Received more than one alignment input, but only one should be specified');
    }

    this.invalidateAlignments();

    if (this.shouldAlignCenter()) this._alignCenter = true;
    if (this.shouldAlignEnd()) this._alignEnd = true;
    if (this.shouldAlignStart()) this._alignStart = true;
  }

  public evaluateSizeInputs(): void {
    if (this.multipleSizesSpecified()) {
      throw new Error('Received more than one size input, but only one should be specified');
    }

    this.invalidateSizes();

    if (this.shouldScaleToX1()) this._x1 = true;
    if (this.shouldScaleToX2()) this._x2 = true;
    if (this.shouldScaleToX3()) this._x3 = true;
    if (this.shouldScaleToX4()) this._x4 = true;
    if (this.shouldScaleToX5()) this._x5 = true;
    if (this.shouldScaleToX6()) this._x6 = true;
  }

  public invalidateAlignments(): void {
    this._alignCenter = this._alignEnd = this._alignStart = false;
  }

  public invalidateSizes(): void {
    this._x1 = this._x2 = this._x3 = this._x4 = this._x5 = this._x6 = false;
  }

  public multipleAlignmentsSpecified(): boolean {
    return this.countAlignmentInputs() > 1;
  }

  public multipleSizesSpecified(): boolean {
    return this.countSizeInputs() > 1;
  }

  public shouldAlignCenter(): boolean {
    return this.centerAligned !== undefined;
  }

  public shouldAlignEnd(): boolean {
    return this.endAligned !== undefined;
  }

  public shouldAlignStart(): boolean {
    return this.startAligned !== undefined;
  }

  public shouldScaleToX1(): boolean {
    return this.x1 !== undefined;
  }

  public shouldScaleToX2(): boolean {
    return this.x2 !== undefined;
  }

  public shouldScaleToX3(): boolean {
    return this.x3 !== undefined;
  }

  public shouldScaleToX4(): boolean {
    return this.x4 !== undefined;
  }

  public shouldScaleToX5(): boolean {
    return this.x5 !== undefined;
  }

  public shouldScaleToX6(): boolean {
    return this.x6 !== undefined;
  }
}
