import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'wlx-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
  // @ContentChild(HeadingLeadComponent) private lead:
  //   | HeadingLeadComponent
  //   | undefined;
  // @ContentChild(HeadingSubdivisionComponent) private subdivision:
  //   | HeadingSubdivisionComponent
  //   | undefined;
}
