import {
  animate,
  AnimationMetadata,
  group,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { classic, Package } from './package';

@Component({
  selector: 'wlx-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [priceUnitChangeTrigger(), allowSelectionChangeTrigger()],
})
export class PackageComponent {
  @Input()
  set package(value: Package | null | undefined) {
    if (value === null || value === undefined) {
      return;
    }
    this._package$.next(value);
  }
  get package(): Package {
    return this._package$.value;
  }

  @Input()
  set allowSelection(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._selectionAllowed$.next(value);
        return;
      case 'string':
        this._selectionAllowed$.next(true);
        return;
      default:
        this._selectionAllowed$.next(false);
        return;
    }
  }
  get allowSelection(): boolean {
    return this._selectionAllowed$.value;
  }

  @Input()
  set showImage(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._showImage$.next(value);
        return;
      case 'string':
        this._showImage$.next(true);
        return;
      default:
        this._showImage$.next(false);
        return;
    }
  }
  get showImage(): boolean {
    return this._showImage$.value;
  }

  @Input() title: string | null = '';
  @Input() subtitle: string | null = '';
  @Input() cents: number | null = 0;
  @Input() centsSubtext: string | null = '';
  @Input() @HostBinding('class.sticky') sticky = false;

  @Output() packageSelect = new EventEmitter<Package>();

  public _package$ = new BehaviorSubject<Package>(classic);
  public _packageOptions = new Array<{ disabled: boolean; package: Package }>();
  public _selectionAllowed$ = new BehaviorSubject<boolean>(false);
  public _selectionDisabled$ = new BehaviorSubject<boolean>(false);
  public _showImage$ = new BehaviorSubject<boolean>(true);
}

function priceUnitChangeTrigger(): AnimationMetadata {
  return trigger('priceUnitChange', [
    transition('* => *', [
      animate(
        '500ms',
        keyframes([
          style({ offset: 0, filter: 'saturate(2) brightness(1.50)' }),
          style({ offset: 1, filter: 'saturate(1) brightness(1)' }),
        ])
      ),
    ]),
  ]);
}

function allowSelectionChangeTrigger(): AnimationMetadata {
  return trigger('allowSelectionChange', [
    transition(':enter', [
      style({ width: 0, opacity: 0 }),
      group([
        animate(
          '500ms 0ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
          keyframes([style({ width: 35, offset: 1 })])
        ),
        animate(
          '1000ms 0ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
          keyframes([style({ opacity: 0, offset: 0.75 }), style({ opacity: 1, offset: 1 })])
        ),
      ]),
    ]),
    transition(':leave', [
      style({ opacity: 1, width: 35 }),
      animate(
        '1000ms 0ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        keyframes([style({ opacity: 0, offset: 0.0 }), style({ width: 0, offset: 0.75 })])
      ),
    ]),
  ]);
}
