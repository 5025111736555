import { Component, Input } from '@angular/core';
import { ButtonModule, DialogService, HeadingModule } from '@wellext/kit';
import { NgIf } from '@angular/common';

@Component({
  selector: 'wlx-message-dialog',
  standalone: true,
  imports: [HeadingModule, NgIf, ButtonModule],
  templateUrl: './message-dialog.component.html',
  styleUrl: './message-dialog.component.css',
})
export class MessageDialogComponent {
  @Input() message: string;
  @Input() title: string;
  enableCloseButton: boolean;
  closeButtonLabel: string;

  constructor(protected dialogService: DialogService) {}
}
