<div class="logo-view">
  <img class="logo" src="/assets/vectors/logos/logo-headless.svg" alt="Logo" />
</div>
<div class="package" [class.no-padding]="(_showImage$ | async) === false">
  <div class="package-display">
    <div class="package-name-and-class">
      <wlx-heading>
        <wlx-heading-lead x4>
          {{ title }}
        </wlx-heading-lead>
        <wlx-heading-subdivision x4>
          {{ subtitle }}
        </wlx-heading-subdivision>
      </wlx-heading>
    </div>
    <div class="package-pricing">
      <span class="package-price-units" [@priceUnitChange]="cents">
        {{ cents < 0 ? '-' : ((cents || 0) / 100 | number : '1.2-2' : 'de') }} €
      </span>
      <div class="package-price-per-hour-per-person">
        {{ centsSubtext }}
      </div>
    </div>
    <div *ngIf="_selectionAllowed$ | async" [@allowSelectionChange]="allowSelection" class="package-select">
      <wlx-icon x2 class="package-select-icon" [name]="'chevron-down'"></wlx-icon>
      <select
        [ngModel]="_package$ | async"
        (ngModelChange)="packageSelect.emit($event); _package$.next($event)"
        [disabled]="(_selectionDisabled$ | async) || false"
        class="package-select-field"
      >
        <option
          *ngFor="let packageOption of _packageOptions"
          [disabled]="packageOption.disabled"
          [ngValue]="packageOption.package"
        >
          {{ packageOption.package.display.name }}
        </option>
      </select>
    </div>
  </div>
</div>
