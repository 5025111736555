import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingBaseComponent } from '../heading';

@Component({
  selector: 'wlx-heading-lead',
  template: '<ng-content></ng-content>',
  styleUrls: ['./lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingLeadComponent extends HeadingBaseComponent {
  constructor() {
    super();
  }
}
